import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default () => (
    <div>
        <Helmet>
            <title>Oberlin Photo Stories</title>
            <meta name="title" content="Oberlin Photo Stories: Oberlin College through a photographer‘s lens" />
            <meta name="description" content="Oberlin College’s photo stories offer a glimpse into classrooms, labs, libraries, concert halls, campus life, and more." />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://photo-stories.oberlin.edu/" />
            <meta property="og:title" content="Oberlin Photo Stories: Oberlin College through a photographer’s lens" />
            <meta property="og:description" content="Oberlin College’s photo stories offer a glimpse into classrooms, labs, libraries, concert halls, campus life, and more." />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/albino-squirrel.JPG" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1333" />
            <meta property="og:image:alt" content="An albino squirrel on a tree." />
            <meta property="fb:app_id" content="1571317633107125" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://photo-stories.oberlin.edu/" />
            <meta property="twitter:title" content="Oberlin Photo Stories: Oberlin College through a photographer’s lens" />
            <meta property="twitter:description" content="Oberlin College’s photo stories offer a glimpse into classrooms, labs, libraries, concert halls, campus life, and more." />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/albino-squirrel.JPG" />
            <meta property="twitter:image:alt" content="An albino squirrel on a tree." />
        </Helmet>
        <Layout>
        <main>
            <div className="jumbotron jumbotron-fluid home text-center text-white mb-0 missing py-auto">
                <div className="container py-5">
                    <h1 className="display-1">Aw, nuts!</h1>
                    <p className="lead">You just tried to access a page that doesn&rsquo;t exist!</p>
                    <a href="/"><p className="btn btn-light"><span className="fas fa-home"></span> Home</p></a><br />
                    <small className="font-italic">Photo credit: Brannon Rockwell-Charland &rsquo;14</small>
                </div>
            </div>
        </main>
        </Layout>
    </div>
)